<template>
  <div class="container-fluid text-left mt-3">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <h4>Patient Search</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">Patient ID</label>
              <input class="form-control" type="text" v-model="participantID"/>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">Initials</label>
              <input class="form-control" type="text" v-model="initials"/>
            </div>
          </div>

          <div class="col-xs-12 col-sm-4">
            <div class="form-group">
              <label class="control-label">Date of Birth</label>
              <input class="form-control" type="text" v-model="dob"/>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button class="btn nw-btn-outline-info" @click="search()">Search</button>
        </div>
        <errors :resp="nc.resp" :retval="nc.retval" class="mt-2"></errors>
        <div class="row mt-2">
          <div class="col-6 text-left">
            <button class="btn btn-outline-secondary" @click="back()"><i class="fa fa-chevron-left"></i></button>
          </div>
          <div class="col-6 text-right">
            {{ skip }}
            <button class="btn btn-outline-secondary" @click="next()"><i class="fa fa-chevron-right"></i></button>
          </div>
        </div>

        <table class="table table-hover table-sm mt-2">
          <thead><tr><th>Patient ID</th><th>Initials</th><th>Date of Birth</th></tr></thead>
          <tbody style="cursor:pointer">
            <tr v-for="item in arr" @click="select(item.oid)">
              <td>{{ item.participantID}}</td>
              <td>{{ item.initials}}</td>
              <td>{{ item.dob}}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Errors from '@/components/Errors'

export default {
  components: { Errors },
  mounted () {
      this.search()
  },
  data () {
    return {
      participantID: '',
      initials: '',
      dob: '',
      oldPid: '',
      skip: 0,
      limit: 10,
      nc: NetClient,
      arr: []
    }
  },
  methods: {
    select (_oid) {
      NetClient.doRequest('rest/session', { action: 'selectPatient', patientID: _oid }).then(msg => {
        if('OK' == msg.retval) this.$router.push({ name: 'SleepSummary'})
      })
    },
    search () {
      if(this.oldPid != this.participantID)
      {
        this.skip = 0
        this.oldPid = this.participantID
      }

      var req = {
        action: 'searchPatient',
        skip: this.skip,
        limit: this.limit,
        participantID: this.participantID,
        initials: this.initials,
        dob: this.dob
      }

      NetClient.doRequest('rest/patient', req).then(msg => {
        if(typeof msg.data != 'undefined') this.arr = msg.data
        else this.arr = []
      })
    },
    sessionone () {
      this.$router.push('/participant/sessions')
    },
    next () {
      this.skip = this.skip + this.limit
      this.search()
    },
    back () {
      this.skip = this.skip - this.limit
      if(this.skip < 0) this.skip = 0
      this.search()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
